<template>
  <div class="loginWrap">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        <div class="userInfoWrap mb20" :style="{backgroundImage: 'url('+loginBg+')'}">
          <div class="userInfoBar">
            <div class="title mb20"><img :src="title" alt="账户中心"></div>
            <div class="loginPage">
              <loginWX :type="0" @forgetPsw="forgetPsw"></loginWX>
            </div>
          </div>
        </div>
        <div class="downCons bgWhite">
          <downItemWX :type="2"></downItemWX>
        </div>
      </div>
    </div>
    <pubMask v-if="isShowPask" :isShowPask="isShowPask" :propShowSwitch="showSwitch" :title="'忘记密码'"></pubMask>
  </div>
</template>

<script>
  import menuWX from '@/components/Menu'
  import downItemWX from '@/views/downLoad/item'
  import loginWX from '@/views/login/login'
  import pubMask from '@/components/pubMask'
  import eventBus from '@/common/bus.js'

  export default {
    name: 'login',
    components:{
      menuWX,
      downItemWX,
      loginWX,
      pubMask,
    },
    data(){
      return {
        isShowPask:false,
        showSwitch:0,
        userName:'',
        loginBg:require('@/assets/imgs/login/loginBg.png'),
        title:require('@/assets/imgs/login/title.png'),
        downDatas:[
          {
            version_name:'【推荐下载】舞侠OL《你的名字》官方最新极速下载器',
            version:'版本号：V1.6（舞侠OL客户端版本V5.0.8）',
            size:'容量：1.93MB',
            update_date:'更新时间：8月30日',
            intro:'安装说明：最适合新手的版本，让您极速体验舞侠OL！',
          },
        ]
      }
    },
    created(){
      eventBus.$on('closeMask',this.closeMask)
    },
    mounted() {
      document.documentElement.scrollTop = 0
    },
    methods: {
      closeMask(){
        this.isShowPask = false;
        this.showSwitch = 0
      },
      forgetPsw(){
        this.isShowPask = true;
        this.showSwitch = 1
      },
      onSubmit() {
        console.log('submit!');
      }
    }
  }
</script>

<style lang="less">
  .loginWrap{
    .loginPage{
      .el-form{
        padding-left:240px;
      }
      .el-form-item{
        margin-bottom: 30px;
      }
      .el-form-item__label{
        font-weight: 600;
        font-size: 18px;
        &:before{
          display: none;
        }
      }
      .el-input__inner{
        padding:0 6px;
        width: 240px;
        border-radius: 0;
        border: 1px solid #999999;
        background: none;
        box-sizing: border-box;
        &::placeholder{
          font-size: 12px;
        }
      }
      .codeWrap{
        display: flex;
        width: 240px;
        justify-content: center;
        align-items: center;
        .el-form-item__content{
          width: 100%;
        }
        .el-input__inner{
          width: 130px;
          min-width: 130px;
        }
        .codeImg{
          width: 100px;
          min-width:100px;
          height: 40px;
          line-height: 40px;
          display: inline-block;
          cursor: pointer;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
      .submitWrap{
        margin-bottom: 7px;
        .el-form-item__content{
          margin-left:0!important;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 340px;
        }
        .btn{
          margin: 0 20px;
          width: 130px;
          min-width: 130px;
          span{
            font-size: 20px;
          }
        }
      }
      .forgetPsw{
        width: 340px;
        text-align: center;
        span{
          margin:0 34px;
          font-size: 12px;
          text-decoration: underline;
          &:hover{
            color: #D22190;
            cursor: pointer;
          }

        }
      }
    }
  }
</style>
<style lang="less" scoped>
  .loginWrap{
    .userInfoWrap{
      padding:100px 40px 50px 40px;
      height: 604px;
      box-sizing: border-box;
      background-repeat: no-repeat;
      .userInfoBar{
        .title{
          margin-bottom: 40px;
          text-align: center;
        }
      }
    }
    .downCons{
      padding-top: 50px;
    }
  }
</style>
